.web-header{
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    background: linear-gradient(180deg, rgba(8, 8, 8, 0.8) 0%, rgba(36, 37, 41, 0.8) 60.17%);
    font-family: "Inter";
}

.web-header-logo{
    position: absolute;
    left: 50px;
    bottom: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    z-index: 11;
}
.web-header-logo-container{
    padding-bottom: 5%;
    cursor: pointer;
}

.web-header-logo-img{
    width: 12vw;
    cursor: pointer;
}

.web-header-logo-text{
    margin: 0;
    margin-left: 18px;
    font-size: 1.5vw;
    color: rgba(188, 188, 188, 1);
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
}

.web-header-menu{
    position: absolute;
    width: 100%;
    bottom: 2%;
    display: flex;
    flex-direction: row;
    padding-left: 35%;
}

.web-header-menu-item{
    width: 12vw;
}

.web-header-menu-link{
    text-decoration: none;
    color: rgba(188, 188, 188, 1);
    font-size: 1.5vw;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .web-header-logo-img{
        width: 16vw !important;
    }

    .web-header-menu-item{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .web-header-menu-link{
        font-size: 2.5vw !important;
    }
}