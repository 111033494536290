.web-about-body{
    width: 100%;
    position: relative;
    font-family: 'Inter';
}

.about-first{
    position: relative;
}

.about-logo{
    position: absolute;
    width: 40%;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.about-people{
    position: absolute;
    width: 35%;
    bottom: -4%;
    left: 50%;
    transform: translateX(-50%);
}

.about-floor{
    position: relative;
    margin-top: -6px;
    width: 100%;
}