.web-body{
    width: 100%;
    padding-top: 100px;
    position: relative;
    font-family: 'Inter';
}

.web-partner{
    width: 100%;
    padding: 60px;
    background-color: rgba(0, 0, 0, 1);
}

.web-partner-title{
    font-size: 2rem;
    font-weight: 300;
    color: rgba(188, 188, 188, 1);
    margin-bottom: 50px;
}

.web-partner-title-a{
}

.web-partner-title-b{
}

.web-partner-img{
    width: 260px;
}

.web-partner-transition{
    height: 60px;
    background: linear-gradient(180deg, #010101 0%, #1F2021 100%, #1A1A1A 100.01%);
}

.web-comingsoon{
    width: 100%;
    position: relative;
}

.web-comingsoon-text{
    color: rgba(188, 188, 188, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.web-comingsoon-title{
    text-align: center;
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    .css-1qzevvg{
        width: 40% !important;
    }

    .slide-show{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
  