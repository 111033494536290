.slide-show-top{
    background: linear-gradient(180deg, rgba(24,26,29,0), rgba(24,24,24,0.5), rgba(26, 26, 26, 1));
    height: 60px;
    top:-60px;
}

.slide-show{
    background-color: rgba(26, 26, 26, 1);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 100px;
    padding-right: 100px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.slide-item{
    color: rgba(255, 255, 255, 1);
    width: 420px;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}

.slide-item-desc{
    margin-top: 10px;
    padding: 5px;
}

.slide-item-desc-title{
    font-weight: 700;
    margin-right: 5px;
}