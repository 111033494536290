.web-shop-body{
    width: 100%;
    position: relative;
    font-family: 'Inter';
}

.shop-comingsoon-text{
    position: absolute;
    left: 50%;
    top: 35%;
    color: white;
    font-size: 30px;
    font-weight: 800;
    transform: translateX(-50%);
}
