.web-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
}

.web-footer-social-img{
    height: 30px;
}

.web-footer-social{
    margin-left: 35px;
    margin-right: 35px;
}