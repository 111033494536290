.web-carousel{
    width: 100vw;
    height: fit-content;
}

.carousel-container{
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 55%;
}

.carousel-logo-container{
    top: 41%;
    transform: translateY(-50%);
    mix-blend-mode: difference;
}

.carousel-logo-img{
    mix-blend-mode: difference;
}

.css-1fzpoyk{
    min-width: 70%;
    background-color: black;
}