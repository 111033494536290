.web-project-body{
    width: 100%;
    padding-top: 66px;
    position: relative;
    font-family: 'Inter';
    background-image: url("projectbg.png");
    background-size: 100% auto;
}

.web-project-body-menu{
    padding-left: 45px;
    color: rgba(188, 188, 188, 1);
    font-size: 2rem;
    font-weight: 200;
    z-index: 11;
    position: relative;
}

.project-menu-selected{
    font-weight: 700;
    font-style: italic;
    border-bottom: 1.5px solid rgba(188, 188, 188, 1);
}

.project-menu-item{
    cursor: pointer;
    position: relative;
}

.project-item{
    margin-top: -6%;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 70vw;
}

.project-item-text-left{
    text-align: right;
    position: absolute;
    left: 6.5%;
    bottom: 23%;
    width: 15vw;
    color: rgba(255, 255, 255, 1);
}

.project-item-text-right{
    text-align: left;
    position: absolute;
    right: 6.5%;
    bottom: 23%;
    width: 15vw;
    color: rgba(255, 255, 255, 1);
}

.project-item-title{
    font-size: 1rem;
}

.project-item-image{
    width: 100%;
    position: relative;
}

.project-item-cover, .project-item-name-container{
    position: absolute;
    left: 16.8%;
    top: 23.6%;
    height: 51.3%;
    cursor: pointer;
    aspect-ratio : 1 / 1;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.project-item-cover-right, .project-item-name-right-container{
    position: absolute;
    right: 16.8%;
    top: 23.6%;
    height: 51.3%;
    cursor: pointer;
    aspect-ratio : 1 / 1;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.project-item-name{
    position: absolute;
    color: rgba(188, 188, 188, 1);
    font-size: 2vw;
    font-weight: 900;
    cursor: pointer;
    bottom: -30px;
}

.project-item-name-right{
    position: absolute;
    color: rgba(188, 188, 188, 1);
    font-size: 2vw;
    font-weight: 900;
    cursor: pointer;
    bottom: -30px;
}

.project-item-image-bg-right{
    transform: scaleX(-1);
}

.video-player{
    position: fixed;
    z-index: 11;
    top: 0;
    padding-top: 100px;
    height: 85vh;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    animation-name: fadeInBottom;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: 0.2s;
}

.video-player-iframe{
    width: 100%;
    height: 90%;
}

.video-player-close{
    background-color: rgba(8, 8, 8, 0.8);
    top: 100px;
    height: 9%;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 30px;
}

.video-player-close-logo{
    height: 30px;
    position: absolute;
    left: 10px;
}

.video-player-close-icon{
    cursor: pointer;
    color: white;
    width: 30px;
    text-align: center;
    font-size: 25px;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(50%);
    }
    to { opacity: 1 }
}

@media only screen and (max-width: 600px) {
    .web-project-body-menu{
        font-size: 20px;
    }

    .project-item{
        width: 100vw;
    }

    .project-item-text-left{
        width: 30vw;
        font-size: 12px;
        bottom: 50%;
        transform: translateY(50%);
    }

    .project-item-text-right{
        width: 30vw;
        font-size: 12px;
        bottom: 50%;
        transform: translateY(50%);
    }

    .project-item-title{
        font-size: 14px;
    }

    .project-list{
        padding-bottom: 100px;
    }
  }
  